import { setToken, getToken } from '@/libs/util'
import axios from '@/libs/api.request'
//  import { getMyMasterctrlFromTree } from '@/api/Used';

export default {
    state: {
        token: getToken(),
        hasGetInfo: false,
        userInfo: {
            userName: '',
            groupID: '',
            powerID: '',
            passWord: '',
            realName: '',
            puser: '',
            mobile: '',
            wechatGroup: '',
            remark: '',
            userImg: '',
            electricPartID: '',
            userRouterPower: [],
            access: [],
            usersList: [],
            usedMark: 1,
            gisMapUrl: '',
            gisMap: '',
            lng: 121.239,
            lat: 37.55,
            maxLevel: 0,
            minLevel: 0,
            defaultLevel: 0,
            mapKey: ''
        },
        electricRouter: [],
        GroupRouter: [],
        powerRouter: [],
        relationShip: [],
        nodeType: [],
        powerModel: [],
        tree: [],
        netType: [],
        produceType: [],
        company: [],
        masterCtrl: [],
        monitorType: [],
        thresholdType: [],
        HandlerMarkList: [{ id: 0, name: '未处理' }, { id: 1, name: '已处理' }],
        CallMarkList: [{ id: 0, name: '未通知' }, { id: 1, name: '已通知' }],
        alarmTypeList: [],
        alarmLevelList: [],
        mcEventType: [],
        shoutVoiceList: [],
        phaseCtrl: []
    },
    getters: {
        getDevOnlineStatus: (state) => (treeid) => {
            const DevStatus = {
                produceTypeID: 'UnKnow',
                Status: true
            }
            for (let i = 0; i < state.masterCtrl.length; i++) {
                const masterNode = state.masterCtrl[i]
                if (masterNode.treeId === treeid) {
                    DevStatus.produceTypeID = masterNode.produceTypeId
                    DevStatus.Status = masterNode.status
                    break
                }
            }
            return DevStatus
        },
        getAlarmLevelName: (state) => (alarmLevelID) => {
            if (state.alarmLevelList == null || state.alarmLevelList.length === 0) {
                return '未知报警等级'
            } else {
                const alarmLevelJSON = state.alarmLevelList.filter(t => t.levelId === alarmLevelID)
                return alarmLevelJSON.length === 0 ? '未知报警等级' : alarmLevelJSON[0].levelName
            }
        },
        getAlarmTypeName: (state) => (alarmTypeID) => {
            if (state.alarmTypeList == null || state.alarmTypeList.length === 0) {
                return '未知报警类型'
            } else {
                const alarmTypeJSON = state.alarmTypeList.filter(t => t.alarmTypeId === alarmTypeID)
                return alarmTypeJSON.length === 0 ? '未知报警类型' : alarmTypeJSON[0].alarmTypeName
            }
        },
        getMasterName: (state) => (masterID) => {
            if (state.masterCtrl == null || state.masterCtrl.length === 0) {
                return '未知设备'
            } else {
                const masterJSON = state.masterCtrl.filter(t => t.treeId === masterID)
                return masterJSON.length === 0 ? '未知设备' : masterJSON[0].name
            }
        },
        getMasterProduceType: (state) => (masterID) => {
            if (state.masterCtrl == null || state.masterCtrl.length === 0) {
                return 'undefined'
            } else {
                const masterJSON = state.masterCtrl.filter(t => t.treeId === masterID)
                return masterJSON.length === 0 ? 'undefined' : masterJSON[0].produceTypeId
            }
        }
    },
    mutations: {
        /**
        * @author 张现利
        * @time 2022.03.31
        * @func 存储登录用户中心定位的经度
        */
         setLng (state, lng) {
            state.lng = lng;
        },
        /**
        * @author 张现利
        * @time 2022.06.13
        * @func 存储登录用户GIS地图的最小层级
        */
         setMinLevel (state, minLevel) {
            state.minLevel = minLevel;
        },
        /**
        * @author 张现利
        * @time 2022.06.13
        * @func 存储登录用户GIS地图的最大层级
        */
         setMaxLevel (state, maxLevel) {
            state.maxLevel = maxLevel;
        },
        /**
        * @author 张现利
        * @time 2022.06.13
        * @func 存储登录用户GIS地图的默认层级
        */
         setDefaultLevel (state, defalutLevel) {
            state.defalutLevel = defalutLevel;
        },
        /**
        * @author 张现利
        * @time 2022.03.31
        * @func 存储登录用户中心定位的纬度
        */
         setLat (state, lat) {
            state.lat = lat;
        },
        /**
        * @author 张现利
        * @time 2022.03.31
        * @func 存储登录用户的GIS地址
        */
         setGisMapUrl (state, gisMapUrl) {
            state.gisMapUrl = gisMapUrl;
        },
        /**
        * @author 张现利
        * @time 2022.03.31
        * @func 存储登录用户的GIS图库
        */
         setGisMap (state, gisMap) {
            state.gisMap = gisMap;
        },
        /**
        * @author 张现利
        * @time 2022.03.31
        * @func 存储登录用户的基础信息
        */
        setUserInfo (state, userInfo) {
            state.userInfo = userInfo;
        },
        /**
        * @author 张现利
        * @time 2022.03.31
        * @func 设置token
        */
        setToken (state, token) {
            state.token = token;
            setToken(token);
        },
        /**
        * @author 张现利
        * @time 2022.03.31
        * @func 设置token
        */
        setUserList (state, usersList) {
            state.usersList = usersList;
        },
        /**
        * @author 张现利
        * @time 2022.03.31
        * @func 设置 hasGetInfo
        */
        setHasGetInfo (state, hasGetInfo) {
            state.hasGetInfo = hasGetInfo;
        },
        /**
        * @author 张现利
        * @time 2022.04.06
        * @func 设置 电力专业
        */
        setElectricRouter (state, electricRouter) {
            state.electricRouter = electricRouter;
        },
        /**
        * @author 张现利
        * @time 2022.04.06
        * @func 设置 项目分组
        */
        setGroupRouter (state, GroupRouter) {
            state.GroupRouter = GroupRouter;
        },
        /**
        * @author 张现利
        * @time 2022.04.06
        * @func 设置 页面路由
        */
        setPowerRouter (state, powerRouter) {
            state.powerRouter = powerRouter;
        },
        /**
        * @author lhj
        * @time 2022.04.12
        * @func 设置 页面厂家
        */
        setRelationShip (state, relationShip) {
            state.relationShip = relationShip;
        },
        /**
        * @author lhj
        * @time 2022.04.14
        * @func 设置 页面厂家
        */
        setNodeType (state, nodeType) {
            state.nodeType = nodeType;
        },
        /**
        * @author lhj
        * @time 2022.04.14
        * @func 设置 页面供电类型
        */
        setPowerModel (state, powerModel) {
            state.powerModel = powerModel;
        },
        /**
        * @author zxl
        * @time 2022.04.16
        * @func 缓存该用户的设备树
        */
        setTree (state, tree) {
            state.tree = tree;
        },
        /**
        * @author lhj
        * @time 2022.04.16
        * @func 设置 页面通讯类型
        */
        setNetType (state, netType) {
            state.netType = netType;
        },
        /**
        * @author lhj
        * @time 2022.04.16
        * @func 设置 页面产品类型
        */
        setProduceType (state, produceType) {
            state.produceType = produceType;
        },
        /**
        * @author lhj
        * @time 2022.04.16
        * @func 设置 页面公司
        */
        setCompany (state, company) {
            state.company = company;
        },
        /**
        * @author 张现利
        * @time 2022.04.19
        * @func 设置产品信息
        */
        setMasterCtrl (state, masterCtrl) {
            state.masterCtrl = masterCtrl;
        },
        /**
        * @author lhj
        * @time 2022.04.20
        * @func 设置 页面监测类型
        */
        setMonitorType (state, monitorType) {
            state.monitorType = monitorType;
        },
        /**
        * @author lhj
        * @time 2022.04.20
        * @func 设置 页面阈值类型
        */
        setThresholdType (state, thresholdType) {
            state.thresholdType = thresholdType;
        },
        /**
        * @author zxl
        * @time 2022.04.24
        * @func 设置 页面报警类型
        */
        setAlarmTypeList (state, alarmTypeList) {
            state.alarmTypeList = alarmTypeList
        },
        /**
        * @author zxl
        * @time 2022.04.24
        * @func 设置 页面报警等级
        */
         setAlarmLevelList (state, alarmLevelList) {
            state.alarmLevelList = alarmLevelList
        },
        /**
        * @author lhj
        * @time 2022.05.07
        * @func 设置 页面井盖事件类型
        */
        setMcEventType (state, mcEventType) {
            state.mcEventType = mcEventType;
        },
        /**
        * @author lhj
        * @time 2022.07.05
        * @func 设置 机器人告警音频类型
        */
        setShoutVoiceList (state, shoutVoiceList) {
            state.shoutVoiceList = shoutVoiceList;
        },
        /**
        * @author lhj
        * @time 2022.07.27
        * @func 设置 相位
        */
        setPhaseCtrl (state, phaseCtrl) {
            state.phaseCtrl = phaseCtrl;
        }
    },
    actions: {
        getUserInfo () {
            return new Promise((resolve, reject) => {
                try {
                    axios.request({
                        url: 'GetBaseInfo',
                        headers: {
                            'content-type': 'application/json',
                            token: this.state.user.token
                        },
                        method: 'post'
                    }).then(res => {
                        if (res.data.code === 200) {
                            const baseInfo = res
                            this.commit('setHasGetInfo', true);
                            this.commit('setMasterCtrl', []);
                            this.commit('setUserInfo', baseInfo.data.data.users)
                            this.commit('setElectricRouter', baseInfo.data.data.electricRouter)
                            this.commit('setGroupRouter', baseInfo.data.data.groupRouter)
                            this.commit('setPowerRouter', baseInfo.data.data.powerRouter)
                            this.commit('setRelationShip', baseInfo.data.data.relationShip)
                            this.commit('setNodeType', baseInfo.data.data.nodeType)
                            this.commit('setPowerModel', baseInfo.data.data.powerModel)
                            this.commit('setTree', baseInfo.data.data.tree)
                            this.commit('setNetType', baseInfo.data.data.netType)
                            this.commit('setProduceType', baseInfo.data.data.produceType)
                            this.commit('setCompany', baseInfo.data.data.company)
                            this.commit('setMonitorType', baseInfo.data.data.monitorType)
                            this.commit('setThresholdType', baseInfo.data.data.thresholdType)
                            this.commit('setAlarmTypeList', baseInfo.data.data.alarmTypeList)
                            this.commit('setAlarmLevelList', baseInfo.data.data.alarmLevelList)
                            this.commit('setMcEventType', baseInfo.data.data.mcEventType)
                            this.commit('setGisMapUrl', baseInfo.data.data.gisMapUrl)
                            this.commit('setGisMap', baseInfo.data.data.gisMap)
                            this.commit('setLng', baseInfo.data.data.lng)
                            this.commit('setLat', baseInfo.data.data.lat)
                            this.commit('setShoutVoiceList', baseInfo.data.data.shoutVoiceList)
                            this.commit('setPhaseCtrl', baseInfo.data.data.phaseCtrl)
                            resolve(res)
                        } else {
                            reject(res)
                        }
                    }).catch(err => {
                        window.console.log('首页异常' + err);
                        reject(err)
                    });
                } catch (error) {
                    reject(error)
                }
            })
        },
        getMasterCtrlInfo () {
            return new Promise((resolve, reject) => {
                try {
                    /*
                    const AllCtrl = getMyMasterctrlFromTree();
                    if (AllCtrl.length > 0) {
                        const data = {
                            masterCtrlList: AllCtrl
                        }
                        axios.request({
                            url: 'GetMasterCtrlInfo',
                            data,
                            headers: {
                                'content-type': 'application/json',
                                token: this.state.user.token
                            },
                            method: 'post'
                        }).then(res => {
                            if (res.data.code === 200) {
                                const baseInfo = res
                                this.commit('setMasterCtrl', baseInfo.data.data)
                                resolve(true)
                            } else {
                                reject(res)
                            }
                        }).catch(err => {
                            reject(err)
                        });
                    } else {
                        window.console.log('获取用户管理设备信息失败，因为没有从树中找到该用户所管理的设备');
                        //  reject()
                    }
                    */
//                    console.log('GetMasterCtrl')
                    axios.request({
                        url: 'GetMasterCtrlInfoBytoken',
                        headers: {
                            'content-type': 'application/json',
                            token: this.state.user.token
                        },
                        method: 'post'
                    }).then(res => {
                        if (res.data.code === 200) {
                            const baseInfo = res
                            this.commit('setMasterCtrl', baseInfo.data.data)
                            resolve(true)
                        } else {
                            reject(res)
                        }
                    }).catch(err => {
                        reject(err)
                    });
                } catch (error) {
                    reject(error)
                }
            })
        }
    },
    modules: {
    }
}
